<!-- ChildComponent.vue -->
<template>
    <b-form  @submit="saveForm">

        <div v-for="(item, index) in dataMileStone" :key="index">
            <div class="row">
                <!-- Tên mốc nhận thưởng -->
                <div class="col-md-1 text-center">
                    <h2 class="title-block">MỐC {{ index + 1 }}</h2>
                    <div>
                        <b-button class="btn" @click="deleteMileStone(index)">
                            <i class="flaticon-delete"></i>
                        </b-button>
                    </div>
                </div>
                <div class="col-md-8">
                    <b-form-group id="input-dk-2" label="Số điểm đạt mốc(*)" label-for="ms-title">
                        <b-form-input id="ms-title" @keydown="numbersOnly($event)" autocomplete="off" v-model="dataMileStone[index].point"
                                      placeholder="Số điểm đạt mốc" required
                                      ></b-form-input>
                    </b-form-group>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                id="input-type-gift"
                                label="Kiểu quà tặng:"
                                label-for="gift-type"
                            >
                                <b-form-select
                                    id="gift-type"
                                    v-model="dataMileStone[index].gift_type"
                                    :options="type_gift"
                                    lable=""
                                    required
                                    placeholder="Chọn loại quà tặng"
                                ></b-form-select>
                            </b-form-group>

                        </div>
                        <div class="col-md-6">
                            <template v-if="dataMileStone[index].gift_type === 1">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Voucher Code (*)"
                                    label-for="dk-2"
                                >
                                    <div class="input-group">
                                        <b-form-input
                                            id="dk-2"
                                            @keydown.space.prevent
                                            v-model="dataMileStone[index].gift"
                                            required
                                            placeholder="Voucher Code"
                                        ></b-form-input>
                                        <b-btn class="btn btn-sm btn-info"  @click="checkInfoCoupon(dataMileStone[index].gift)">
                                            Check
                                        </b-btn>
                                    </div>
                                </b-form-group>
                            </template>
                            <template v-if="dataMileStone[index].gift_type === 3">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Số điểm cộng (*)"
                                    label-for="dk-2"
                                >
                                    <b-form-input
                                        id="dk-2"
                                        v-model="dataMileStone[index].gift"
                                        required
                                        @keydown="numbersOnly($event)"
                                        min="1"
                                        placeholder="Số điểm cộng"
                                    ></b-form-input>
                                </b-form-group>
                            </template>
                            <template v-if="dataMileStone[index].gift_type === 2">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Mệnh giá tiền thưởng (*)"
                                    label-for="dk-2"
                                    description="Mệnh giá tối thiểu 100đ"
                                >
                                    <b-form-input
                                        id="dk-2"
                                        v-model="dataMileStone[index].gift"
                                        required
                                        @keydown="numbersOnly($event)"
                                        min="100"
                                        placeholder="Mệnh giá tiền thưởng"
                                    ></b-form-input>
                                </b-form-group>
                            </template>
                            <template v-if="dataMileStone[index].gift_type === 4">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Combo quà tặng (*)"
                                    label-for="dk-2"
                                >
                                    <multiselect
                                        v-model="dataMileStone[index].gift"
                                        :options="combo_option"
                                        :multiple="false"
                                        track-by="text"
                                        label="text"
                                        placeholder="Chọn combo"
                                    ></multiselect>
                                </b-form-group>
                            </template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                id="input-dk-2"
                                label="Tổng số lượng quà phát ra (*)"
                                label-for="dk-2"
                            >
                                <b-form-input
                                    id="dk-2"
                                    v-model="dataMileStone[index].gift_quantity"
                                    required
                                    @keydown="numbersOnly($event)"
                                    min="1"
                                    placeholder="Tổng số lượng quà phát ra"
                                ></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                id="input-group-0"
                                label="Icon:"
                                label-for="input-0"
                            >
                                <div style="display: flex">
                                    <div style="height: 38px; margin-right: 20px" v-if="dataMileStone[index].gift_icon && dataMileStone[index].gift_icon.length >0">
                                        <img
                                            style="width: 38px; height: 38px"
                                            :src="dataMileStone[index].gift_icon"
                                            alt=""
                                        />
                                    </div>
                                    <b-form-file
                                        @change="onImageChange($event,index)"
                                        placeholder="chọn icon"
                                        type="file"
                                        accept="image/*"

                                    ></b-form-file>
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                    <b-form-group id="input-dk-2" label="Tiêu đề thông báo" label-for="ms-title">
                        <b-form-input id="ms-noti" v-model="dataMileStone[index].notification_title" maxlength="200"
                                      placeholder="Tiêu đề thông báo"
                                      ></b-form-input>
                    </b-form-group>
                    <!-- Nội dung thông báo -->
                    <b-form-group id="input-dk-2" label="Nội dung thông báo" label-for="ms-desc">
                        <b-form-textarea id="ms-desc" v-model="dataMileStone[index].notification_description"
                                         placeholder="Nhập nội dung thông báo" maxlength="500"
                                         rows="6"></b-form-textarea>
                    </b-form-group>
                </div>
                <!-- Thông báo -->
            </div>
            <hr/>
        </div>
        <!-- Nút thêm mới mốc -->
        <div class="row">
            <b-button variant="primary" style="margin-right: 10px" @click="addMileStone">
                + Thêm mốc BXH
            </b-button>
            <b-button type="submit"  variant="primary">Cập nhập</b-button>
        </div>
        <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    </b-form>
</template>

<script>
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import {Form} from "vform";
import {HasError} from "vform/src/components/bootstrap5";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import Common from "@/core/mixins/common";
import EventRepository from "@/core/repositories/EventRepository";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";

Vue.component(HasError.name, HasError);
Vue.component("multiselect", Multiselect);
// const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");
const CampaignRepository = RepositoryFactory.get("Campaign");
export default {
    props: {
        dataMile: {},
    },
    mixins: [Common],
    components: {
        AlertDialogue,
        Multiselect
    },
    data() {
        return {
            dataMileStone: [{
                id: 0,
                name: "",
                point: "",
                gift_type: "",
                gift: "",
                gift_quantity: "",
                gift_icon: "",
                notification_title: "",
                notification_description: "",
                status: 1
            }],
            form: new Form({
                data: []
            }),
            config_id:0,
            type_gift: [],
            combo_option: [],
            displayNone: true
        };
    },
    created() {
        // this.getScripts();
        this.getTypeGift();
        this.getCombos();
        setTimeout(() => {
            if (this.$route.params.id) {
                this.config_id = this.$route.params.id;
                this.loadMilStone(this.$route.params.id);
            }
        },2000);

    },
    methods: {
        saveForm () {
            let dataMiles =[];
            if(this.dataMileStone.length > 0) {
                this.dataMileStone.forEach((item)=>{
                    if(item.gift_type === 4){
                        item.gift = item.gift.value;
                    }
                    if(item.gift_type === 5){
                        item.gift = 0;
                    }
                    dataMiles.push(item)
                })
            }

            this.$bus.$emit("show-loading", true);
            EventRepository.storeMilestone(this.config_id,{data:dataMiles})
                .then((response) => {
                    if (response) {
                        this.notifyAlert("success", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return this.$router.push({ name: "top-service" });
                    } else {
                        this.notifyAlert("warn", response.data.message);
                        this.$bus.$emit("show-loading", false);
                        return false;
                    }
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });

        },
        async getTypeGift() {
                let response = await CampaignRepository.getOptionsVoucher();
                this.type_gift = response.data.data;
        },
        async getCombos() {
            let response = await CampaignRepository.getListCombo({all: true,limit:1000});
            let body = response.data;
            if (body.data) {
                let that = this;
                body.data.list.data.forEach((item) => {
                    let newObj = {
                        text: item.title,
                        value: item.id
                    }
                    that.combo_option.push(newObj);
                });
            }
        },
        addMileStone() {
            this.dataMileStone.push({
                name: "",
                point: "",
                gift_type: "",
                gift: "",
                gift_quantity: "",
                gift_icon: "",
                notification_title: "",
                notification_description: "",
                status: 1
            });
        },
        async loadMilStone(id) {
            try {
                this.$bus.$emit("show-loading", true);
                let response = await EventRepository.milestone(id);
                if (response.data.error_code === 0) {
                    let data = response.data.data;
                    if(data){
                        this.dataMileStone = [];
                        data.forEach((item) => {
                            if(parseInt(item.gift_type)===4){
                                item.gift = this.combo_option.find(option => option.value == item.gift);
                            }
                            this.dataMileStone.push({
                                id: item.id,
                                name: item.name,
                                point: item.point,
                                gift_type: parseInt(item.gift_type),
                                gift: item.gift,
                                gift_quantity: item.gift_quantity,
                                gift_icon: item.gift_icon,
                                notification_title: item.notification_title,
                                notification_description: item.notification_description,
                                status: item.status
                            });
                        });
                    }
                    this.$bus.$emit("show-loading", false);
                } else {
                    this.$bus.$emit("show-loading", false);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                }
            } catch (error) {
                this.$bus.$emit("show-loading", false);
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: error,
                });
            }
        },
        deleteMileStone(index) {
            // const indexToRemove = index; // Vị trí index của phần tử cần loại bỏ
            this.dataMileStone.splice(index, 1);
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        async checkInfoCoupon (code) {
            let self = this;
            let response = await CampaignRepository.getInfoCoupon(code);
            if (response.status !== 200 || response.data.error_code === 1) {
                self.$bus.$emit("show-loading", false);
                return (self.errorMessage = "Có lỗi khi lấy thông tin");
            }

            self.$bus.$emit("show-loading", false);
            const cfm = await this.$refs.confirmDialogue.show({
                title: "Thông tin Voucher",
                message:
                    "<ul style='text-align: left'><li>"+
                    "ID:  " +response.data.data.id+
                    "</li><li>" +
                    "Code: " +response.data.data.code+
                    "</li><li>" +
                    "Tên: " +response.data.data.name+
                    "</li><li>" +
                    "Số lượng: "+this.numberWithCommas(response.data.data.max_gift)+"</li></ul>",
                okButton: "Ok",
            });

            if (!cfm) {
                this.$bus.$emit("show-loading", false);
                return (self.errorMessage = "Có lỗi khi lấy thông tin");
            }

            this.$bus.$emit("show-loading", false);

        },
        onImageChange(e,position) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let fileSize = files[0].size / 1024 / 1024; // in MiB
            if (fileSize> 2){
                this.$notify({
                    group: "foo",
                    type: "error",
                    title:"Chọn ảnh không vượt quá 2MB",
                });
                return false;
            }
            this.images = files[0];
            this.uploadMedia(position);
            //this.createImage(files[0]);
        },
        uploadMedia: async function (position) {
            this.$bus.$emit("show-loading", true);
            const formData = new FormData();
            formData.append("file", this.images);

            WalletRunRepository.uploadMedia(formData)
                .then((response) => {
                    this.$bus.$emit("show-loading", false);
                    if (1 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                    } else {
                        this.dataMileStone[position].gift_icon = response.data.data.link;
                    }
                })
                .catch((response) => {
                    this.$bus.$emit("show-loading", false);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                });
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
